import { pushEvent } from "@wbly/common";
import type { DataLayer } from "@wbly/common";

export const trackSwitchCountryVisible = () =>
  pushEvent(window?.dataLayer as DataLayer[], "switch_country", "is_visible");

export const trackCloseSwitchCountry = () =>
  pushEvent(
    window?.dataLayer as DataLayer[],
    "",
    "switch_country",
    "click_close_modal"
  );

export const trackCountryNavigation = () =>
  pushEvent(
    window?.dataLayer as DataLayer[],
    "",
    "switch_country",
    `click_navigate_cta`,
    `Navigated to /choose-your-country`
  );

export const trackStayInCountry = () =>
  pushEvent(
    window?.dataLayer as DataLayer[],
    "",
    "switch_country",
    `click_continue_cta`,
    `Stayed in country with prefix: /`
  );
