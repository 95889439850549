import { Button } from "@wbly/ui";
import {
  type CountryListItem,
  type SwitchCountrySlice,
} from "@wbly/data-storefront";
import parse from "html-react-parser";
import type { Marketplace } from "@wbly/client";
import * as Flags from "@wbly/tokens/flag";
import { interpolateText } from "../../util";
import { Close } from "../Header/Icons";
import { useSwitchCountry } from "./useSwitchCountry";
import styles from "./SwitchCountry.module.css";
import { getCurrencySymbol } from "./util/get-currency-symbol";

type SwitchCountryProps = SwitchCountrySlice & {
  marketplace: Marketplace;
  countryList: CountryListItem[];
};

const {
  container,
  modalTitle,
  closeButton,
  usCtaButton,
  switchCtaButton,
  header,
  modalIntroText,
  flagContainer,
  modalSubtitle,
  localeIntroCountryText,
  currencyDetails,
} = styles;

export function SwitchCountry(props: SwitchCountryProps) {
  const {
    marketplace,
    countryList,
    title,
    subtitle,
    usCtaLabel,
    localeCtaLabel,
    localeIntroText,
    switchCtaLabel,
  } = props || {};

  const {
    handleClose,
    geoMarketplace,
    handleNavigateToCountry,
    handleSelectCountry,
    handleStayInCountry,
    openDialog,
  } = useSwitchCountry({ marketplace, countryList });

  if (!openDialog || !geoMarketplace) {
    return null;
  }

  const { countryName, currency, iso, path } = geoMarketplace || {};

  const formattedLocaleIntro = interpolateText(localeIntroText, {
    userLocale: `<em> ${countryName}</em>`,
  });

  const formattedLocaleCTALabel = interpolateText(localeCtaLabel, {
    userCurrency: `<em class=${currencyDetails}>${getCurrencySymbol(currency)} ${currency}</em>`,
    userLocale: `<em>${countryName}</em>`,
  });

  const formattedUsCtaLabel = interpolateText(usCtaLabel, {
    defaultLocale: `<em>${marketplace.identifier}</em>`,
  });

  return (
    <div className={container}>
      <header className={header}>
        <button
          className={closeButton}
          aria-label="close modal"
          data-testid="drawer-close-button"
          onClick={handleClose}
        >
          <Close />
        </button>
      </header>
      <div className="content">
        {title && <h2 className={modalTitle}>{title}</h2>}
        {subtitle && <p className={modalSubtitle}>{subtitle}</p>}

        <div className={modalIntroText}>
          <span className={flagContainer}>
            <img src={Flags[iso]} />
          </span>
          <p className={localeIntroCountryText}>
            {parse(formattedLocaleIntro)}
          </p>
        </div>

        {formattedLocaleCTALabel && (
          <Button
            variant="secondary"
            fullWidth={true}
            size="small"
            onClick={() => handleNavigateToCountry(path)}
          >
            {parse(formattedLocaleCTALabel)}
          </Button>
        )}

        <Button
          onClick={handleStayInCountry}
          fullWidth={true}
          outline={true}
          size="small"
          className={usCtaButton}
        >
          {parse(formattedUsCtaLabel)}
        </Button>

        <Button
          onClick={handleSelectCountry}
          outline={true}
          fullWidth={true}
          size="small"
          className={switchCtaButton}
        >
          {switchCtaLabel}
        </Button>
      </div>
    </div>
  );
}
