const symbols = {
  USD: "$",
  EUR: "€",
  AUD: "$",
  CAD: "$",
  DKK: "kr",
  HKD: "$",
  INR: "₹",
  NZD: "$",
  SGD: "$",
  SEK: "kr",
  GBP: "£",
  KRW: "₩",
  JPY: "¥",
};

export type CurrencySymbol = keyof typeof symbols;

export function getCurrencySymbol(currency: CurrencySymbol): string {
  return symbols[currency];
}
