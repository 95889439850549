import { useState, useEffect, useCallback } from "react";
import Cookies from "js-cookie";
import type { CountryListItem } from "@wbly/data-storefront";
import {
  trackSwitchCountryVisible,
  trackCountryNavigation,
  trackStayInCountry,
  trackCloseSwitchCountry,
} from "./track-switch-country";

const getLocaleMarketplace = (
  countryList: CountryListItem[],
  countryCode: string
) => {
  return countryList.find((country) => country.iso === countryCode);
};

// TODO: This should become part of the UI state and
// we should transform Overlay to a component in our library
const triggerOverlay = (action: "open" | "close") => {
  const overlay = document.querySelector(".Overlay");

  switch (action) {
    case "open":
      document.body.classList.add("locked");
      overlay.setAttribute("data-open", "true");
      return;
    case "close":
      document.body.classList.remove("locked");
      overlay.setAttribute("data-open", "false");
      return;
  }
};

export const useSwitchCountry = ({ marketplace, countryList }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [geoMarketplace, setGeoMarkeplace] = useState(null);

  const handleNavigateToCountry = useCallback((contentLocale: string) => {
    sessionStorage.setItem("locale_user_override_session", "true");
    triggerOverlay("close");
    setOpenDialog(false);

    if (contentLocale) {
      window.location.href = `/${contentLocale}`;
    }
  }, []);

  const handleSelectCountry = useCallback(() => {
    sessionStorage.setItem("locale_user_override_session", "true");
    window.location.href = "/choose-your-country";
    triggerOverlay("close");
    setOpenDialog(false);
    trackCountryNavigation();
  }, []);

  const handleClose = useCallback(() => {
    sessionStorage.setItem("locale_user_override_session", "true");
    triggerOverlay("close");
    trackCloseSwitchCountry();
    setOpenDialog(false);
  }, []);

  useEffect(() => {
    const checkAndOpenDialog = async () => {
      try {
        // Check for persistent cookie and session override
        const persistentOverride = Cookies?.get("locale_user_override_always");
        const sessionOverride = sessionStorage?.getItem(
          "locale_user_override_session"
        );

        // If either is set, do not open the modal
        if (persistentOverride || sessionOverride) {
          return;
        }

        // Check if user is on the homepage
        if (window.location.pathname === "/") {
          const response = await fetch("/geo");
          const geoData = await response.json();

          // Open modal if the marketplace is US and the user is not in the US
          if (marketplace.identifier === "us" && !geoData.isUS) {
            const { currency, path, iso } = getLocaleMarketplace(
              countryList,
              geoData.country
            );

            trackSwitchCountryVisible();
            setGeoMarkeplace({
              currency,
              path,
              iso,
              countryName: geoData.countryName,
            });

            triggerOverlay("open");
            setOpenDialog(true);
          }
        }
      } catch (error) {
        console.error("Error fetching geolocation data:", error);
      }
    };

    checkAndOpenDialog();
  }, [marketplace, countryList]);

  const handleStayInCountry = useCallback(() => {
    // Set persistent override cookie
    Cookies.set("locale_user_override_always", "true", { expires: 60 });
    sessionStorage.setItem("locale_user_override_session", "true");
    trackStayInCountry();

    // I'm not using handleClose directly here to avoid
    // re-triggering the trackCloseSwitchCountry event
    setTimeout(() => {
      setOpenDialog(false), 300;
      triggerOverlay("close");
    });
  }, []);

  return {
    geoMarketplace,
    openDialog,
    handleClose,
    handleNavigateToCountry,
    handleSelectCountry,
    handleStayInCountry,
  };
};
